import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { newregister } from "src/api/api";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux";
import { logoutUser } from "src/redux/reducer/userReducer"
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PieChart } from "@mui/x-charts/PieChart";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";
import dayjs from "dayjs";
import { getdashboardheader,getdashboardbarchartinvoice,getdashboardpiechart,getdemorequestdashboardbarchart } from "src/api/api"
import { CardActionArea } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import StatesticsCard from 'src/component/Card/StatesticsCard'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from "@mui/x-data-grid";




const Field = React.forwardRef(
  ({ label, type, placeholder, ...props }, ref) => {
    return (
      <div>
        <label className={styles.labelStyle}>{label}</label>
        <input
          {...props}
          ref={ref}
          type={type}
          placeholder={placeholder}
          className={styles.inputStyle}
        />
      </div>
    );
  }
);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const valueFormatter = (value) => `${value}`;


const Dashboard = ({ onRefresh }) => {

  const dispatch = useDispatch()

  const datasetMonths = [
    {
      Count: 0,
      Month: "Jan",
    },
    {
      Count: 0,
      Month: "Feb",
    },
    {
      Count: 0,
      Month: "Mar",
    },
    {
      Count: 0,
      Month: "Apr",
    },
    {
      Count: 0,
      Month: "May",
    },
    {
      Count: 0,
      Month: "Jun",
    },
    {
      Count: 0,
      Month: "Jul",
    },
    {
      Count:0,
      Month: "Aug",
    },
    {
      Count:0,
      Month: "Sep",
    },
    {
      Count:0,
      Month: "Oct",
    },
    {
      Count:0,
      Month: "Nov",
    },
    {
      Count:0,
      Month: "Dec",
    },
  ];

  const dataset = [
    {
      Count: 0,
      Course: "MM",
    },
    {
      Count: 0,
      Course: "SD",
    },
    {
      Count: 0,
      Course: "FICO",
    },
    {
      Count: 0,
      Course: "EWM",
    },
    {
      Count: 0,
      Course: "ABAP",
    },
    {
      Count: 0,
      Course: "TM",
    },
    {
      Count: 0,
      Course: "ARIBA",
    },
    {
      Count:0,
      Course: "SALESFORCE",
    },
  ];


  const user = useSelector((state) => state.user);
  const [headervar,setHeadervar] = useState([]);
  const [followupbarchartvar,setFollowupBarchartvar] = useState(datasetMonths);
  const [barchartvar,setBarchartvar] = useState(datasetMonths);
  const [piechartvar, setPiechartvar] = useState([
    { value: '0', label: "Demo", color: "#663399" },
    { value: '0', label: "Conversion", color: "#008080" },
  ]);

 

  const [displaydate] = useState(moment().format('DD'));
  const [displaymonth] = useState(moment().format('MM'));

  const [from,setFrom] = useState(moment().format('01-MMM-YYYY')||'');

  const [todate,setTodate] = useState(moment().format('DD-MMM-YYYY')||''||'');

  const [fromyear,setFromyear] = useState(moment().format('YYYY')||'');

  const [fromyeartwo,setFromyeartwo] = useState(moment().format('YYYY')||'');
 
  const size = {
    width: 500,
    height: 300,
  };

  const data = [
    { value: 60, label: "Demo"},
    { value: 50, label: "Conversion"},
  ];

  const chartSetting = {
    yAxis: [
      {
        label: "Number Of invoice Count",
      },
    ],
    width: 800,
    height: 350,
    sx: {
      [`.${axisClasses.bottom} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  const chartSettingdemo = {
    yAxis: [
      {
        label: "Revenue",
      },
    ],
    width: 800,
    height: 350,
    sx: {
      [`.${axisClasses.bottom} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(amount);
  };

  useEffect(() => {
    headerload();
    demobarchartload(fromyear);
    barchartinvoicedata(fromyeartwo);
    
  }, [onRefresh,fromyear,fromyeartwo])

  const headerload = async () => {
    const data = await getdashboardheader(user.token)
    if(data==401)
    {
      dispatch(logoutUser());
      return;
    }
    if(data?.IsSuccess)
    {
      setHeadervar(data?.details[0])
    }
   
  }

  const demobarchartload = async (fromyeartwo) => {
    const request = {
      REQUESTDATE: fromyeartwo
    }
    const data = await getdemorequestdashboardbarchart(request,user.token)

    if(data==401)
    {
      dispatch(logoutUser());
      return;
    }

    if(data?.IsSuccess)
    {
      
      const tempdataset = [
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "1")?.INVOICEREVENUE)|| 0,
          Month: "Jan",
        },
        {
          Count:  parseInt(data?.details?.find(item => item.INVOICEMONTH === "2")?.INVOICEREVENUE)|| 0,
          Month: "Feb",
        },
        {
          Count:  parseInt(data?.details?.find(item => item.INVOICEMONTH === "3")?.INVOICEREVENUE)|| 0,
          Month: "Mar",
        },
        {
          Count:  parseInt(data?.details?.find(item => item.INVOICEMONTH === "4")?.INVOICEREVENUE) || 0,
          Month: "Apr",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "5")?.INVOICEREVENUE)|| 0,
          Month: "May",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "6")?.INVOICEREVENUE )|| 0,
          Month: "Jun",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "7")?.INVOICEREVENUE) || 0,
          Month: "Jul",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "8")?.INVOICEREVENUE)|| 0,
          Month: "Aug",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "9")?.INVOICEREVENUE)|| 0,
          Month: "Sep",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "10")?.INVOICEREVENUE)|| 0,
          Month: "Oct",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "11")?.INVOICEREVENUE)|| 0,
          Month: "Nov",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "12")?.INVOICEREVENUE)|| 0,
          Month: "Dec",
        },
      ];

      setFollowupBarchartvar(tempdataset)

    }else{
      const tempdataset = [
        {
          Count: 0,
          Month: "Jan",
        },
        {
          Count:  0,
          Month: "Feb",
        },
        {
          Count:   0,
          Month: "Mar",
        },
        {
          Count:  0,
          Month: "Apr",
        },
        {
          Count:  0,
          Month: "May",
        },
        {
          Count: 0,
          Month: "Jun",
        },
        {
          Count:  0,
          Month: "Jul",
        },
        {
          Count:  0,
          Month: "Aug",
        },
        {
          Count:  0,
          Month: "Sep",
        },
        {
          Count:  0,
          Month: "Oct",
        },
        {
          Count:  0,
          Month: "Nov",
        },
        {
          Count:  0,
          Month: "Dec",
        },
      ];
      setFollowupBarchartvar(tempdataset)
    }
  }

  const barchartinvoicedata = async (fromyear) => {
    const request = {
      REQUESTDATE: fromyear
    }
    const data = await getdashboardbarchartinvoice(request,user.token)
    if(data==401)
    {
      dispatch(logoutUser());
      return;
    }
    if(data?.IsSuccess)
    {
      
      const tempdataset = [
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "1")?.INVOICECOUNT)|| 0,
          Month: "Jan",
        },
        {
          Count:  parseInt(data?.details?.find(item => item.INVOICEMONTH === "2")?.INVOICECOUNT)|| 0,
          Month: "Feb",
        },
        {
          Count:  parseInt(data?.details?.find(item => item.INVOICEMONTH === "3")?.INVOICECOUNT)|| 0,
          Month: "Mar",
        },
        {
          Count:  parseInt(data?.details?.find(item => item.INVOICEMONTH === "4")?.INVOICECOUNT) || 0,
          Month: "Apr",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "5")?.INVOICECOUNT)|| 0,
          Month: "May",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "6")?.INVOICECOUNT )|| 0,
          Month: "Jun",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "7")?.INVOICECOUNT) || 0,
          Month: "Jul",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "8")?.INVOICECOUNT)|| 0,
          Month: "Aug",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "9")?.INVOICECOUNT)|| 0,
          Month: "Sep",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "10")?.INVOICECOUNT)|| 0,
          Month: "Oct",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "11")?.INVOICECOUNT)|| 0,
          Month: "Nov",
        },
        {
          Count: parseInt(data?.details?.find(item => item.INVOICEMONTH === "12")?.INVOICECOUNT)|| 0,
          Month: "Dec",
        },
      ];

    setBarchartvar(tempdataset)

    }else{
      const tempdataset = [
        {
          Count: 0,
          Month: "Jan",
        },
        {
          Count:  0,
          Month: "Feb",
        },
        {
          Count:   0,
          Month: "Mar",
        },
        {
          Count:  0,
          Month: "Apr",
        },
        {
          Count:  0,
          Month: "May",
        },
        {
          Count: 0,
          Month: "Jun",
        },
        {
          Count:  0,
          Month: "Jul",
        },
        {
          Count:  0,
          Month: "Aug",
        },
        {
          Count:  0,
          Month: "Sep",
        },
        {
          Count:  0,
          Month: "Oct",
        },
        {
          Count:  0,
          Month: "Nov",
        },
        {
          Count:  0,
          Month: "Dec",
        },
      ];
      setBarchartvar(tempdataset)
    }
  }

  const piechartload = async () => {
    const request = {
      FROMDATE: dayjs(from).format("DD-MMM-YYYY"),
      TODATE :dayjs(todate).format("DD-MMM-YYYY"),
    }
    const data = await getdashboardpiechart(request,user.token)
    if(data?.IsSuccess)
    {
      const tempdata = [
        { value: data?.DEMO, label: "Demo", color: "#663399" },
        { value: data?.CONVERSION, label: "Conversion", color: "#008080" },
      ];
    setPiechartvar(tempdata)
    
    }
  }

  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: "100%",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          "& > :not(style)": {
            m: 1,
            width: "100%",
          },
        }}
      >
        <StatesticsCard
          count={formatCurrency(headervar?.OVERALL_REVENUE||0)}
          date={''}
          heading= "Overall Revenue"
          mywidth="23%"
        ></StatesticsCard>

        <StatesticsCard
          count={formatCurrency(headervar?.OVERALL_GST||0)}
          date={''}
          heading= "Overall GST"
          mywidth="23%"
        ></StatesticsCard>

        <StatesticsCard
          count={formatCurrency(headervar?.MONTHLY_REVENUE||0)}
          date={displaymonth}
          heading="This Month Revenue"
          mywidth="23%"
        ></StatesticsCard>

        <StatesticsCard
          count={formatCurrency(headervar?.MONTHLY_GST)}
          date={displaymonth}
          heading="This Month GST"
          mywidth="23%"
        ></StatesticsCard>
      </Box>

      {/*  Demo Request Data */}
      <Box
        sx={{
          marginTop:2,
          flexGrow: 1,
          maxWidth: "100%",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          "& > :not(style)": {
            m: 1,
            width: "100%",
          },
        }}
      >
        <Card sx={{ maxWidth: "47%" }}>
          <Box sx={{ p: 2 }}>
          <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
            <Typography variant="body1" component="div">
              {"Revenue Data Without GST"}{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                 sx={{ maxWidth: 160}}
                  views={["year"]}
                  label="Year"
                  format="YYYY"
                  defaultValue={dayjs(fromyear)}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => {
                    demobarchartload(dayjs(newValue).format("YYYY"))
                  }}
                />
              </LocalizationProvider>
              </Stack>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <BarChart
                margin={{
                  top: 5,
                }}
                dataset={followupbarchartvar}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "Month",
                    categoryGapRatio: 0.5,
                  },
                ]}
                series={[
                  {
                    dataKey: "Count",
                    label: "Total Count",
                    valueFormatter,
                    color: "#1976d2",
                  },
                ]}
                {...chartSettingdemo}
              />
            </Stack>
          </Box>
        </Card>



      {/* Conversion Chart Data */}


        <Card sx={{ maxWidth: "47%" }}>
          <Box sx={{ p: 2 }}>
          <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
            <Typography variant="body1" component="div">
              {"Invoice Data"}{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                 sx={{ maxWidth: 160}}
                  views={["year"]}
                  label="Year"
                  format="YYYY"
                  defaultValue={dayjs(fromyeartwo)}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => {
                    barchartinvoicedata(dayjs(newValue).format("YYYY"))
                  }}
                />
                  </LocalizationProvider>
              </Stack>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
               <BarChart
              margin={{
                top: 5,
              }}
              dataset={barchartvar||0}
              xAxis={[
                { scaleType: "band",
                dataKey: "Month",
                categoryGapRatio: 0.5, },
              ]}
              series={[
                {
                  dataKey: "Count",
                  label: "Total Count",
                  valueFormatter,
                  color: "#1976d2",
                },
              ]}
              {...chartSetting}
            />
            </Stack>
          </Box>
        </Card>

        
      </Box>
  
        {/* <Card sx={{ bgcolor: "#8eb18e", maxWidth: '40%' }}>
          <CardHeader />
          <CardContent>
            <PieChart
              colors={["#663399", "#008080"]}
              series={[
                {
                  data: piechartvar,
                  innerRadius: 80,
                },
              ]}
              {...size}
            ></PieChart>
          </CardContent>
        </Card> */}
    </div>
  );
};

export default Dashboard;
