import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./reducer/userReducer"

const startState = {
  user: JSON.parse(localStorage.getItem('user'))
}

const store = configureStore({ reducer: {
  user: userReducer,
} }, startState)

store.subscribe(() => {
  localStorage.setItem('user', JSON.stringify(store.getState().user))
})

export default store 

