import { Get, Post } from "./apiConfig"
import { Delete } from "./apiConfig"

export const login = async ({ email, password }) => {
  const body = {
    EMAIL: email,
    PASSWORD: password,
  }
  const url = "/api/login"
  const data = await Post({ url, body })
  return data
}

export const saveinvoice = async (requestbody, token ) => {
  const url = "/api/saveinvoice"
  const body = requestbody
  const data = await Post({ url, body, token })
  return data
}

export const editinvoiceapi = async (requestbody, token ) => {
  const url = "/api/editinvoice"
  const body = requestbody
  const data = await Post({ url, body, token })
  return data
}

export const displayinvoicehistory = async (request,token) => {
  const url = "/api/invoicedisplay"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getclientdetailsapi = async (request,token) => {
  const url = "/api/getclientdetails"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const loadcomboapi = async ({ token }) => {
  const url = "/api/loadcombo"
  const data = await Post({ url, token })
  return data
}

export const watchlistapi = async ({
  email,
  searchtext,
  course,
  todate,
  fromdate,
  coursestatus,
  revnufilter,
  token,
}) => {
  const url = "/api/mywatchlist"
  const body = {
    OWNER_EMAIL: email,
    SEARCH: searchtext,
    COURSE: course,
    TODATE: todate,
    FROMDATE: fromdate,
    COURSESTATUS: coursestatus,
    REVENUEFILTER: revnufilter,
  }
  const data = await Post({ url, body, token })
  return data
}

export const reportapi = async ({
  email,
  todate,
  fromdate,filter,
  token,
}) => {
  const url = "/api/report"
  const body = {
    OWNER_EMAIL: email,
    TODATE: todate,
    FROMDATE: fromdate,
    FILTER: filter,
  }
  const data = await Post({ url, body, token })
  return data
}

export const addtowatchlist = async ({ email, semail, token }) => {
  const url = "/api/addtowatchlist"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_EMAIL: semail,
  }
  const data = await Post({ url, body, token })
  return data
}

export const viewpaymentdetails = async ({ semail, token }) => {
  const url = "/api/viewpaymentdetails"
  const body = {
    STUDENT_EMAIL: semail,
  }
  const data = await Post({ url, body, token })
  return data
}
export const assignbatch = async ({ semail, sbatch, token }) => {
  const url = "/api/assignbatch"
  const body = {
    STUDENT_EMAIL: semail,
    STUDENT_BATCH: sbatch,
  }
  const data = await Post({ url, body, token })
  return data
}

export const editdata = async ({
  semail,
  batch,
  email,
  membershiptype,
  trainer,
  selectedcourse,
  maincoursecategory,
  cfees,
  discount,
  token,
}) => {
  const url = "/api/editdata"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_EMAIL: semail,
    BATCH: batch,
    MEMBERSHIPTYPE: membershiptype,
    TRAINER: trainer,
    SELECETEDCOURSE: selectedcourse,
    MAINCOURSE_CATEGORY: maincoursecategory,
    COURSEFEES: cfees,
    DISCOUNT: discount
  }

  const data = await Post({ url, body, token })
  return data
}

export const loadCombo = async({token})=>{
  const url = "api/loadcombo"
  const body = {}
  const data = await Post({url, body, token})
  return data
}

export const gettargets = async ({ email, token }) => {
  const url = "/api/gettargets"
  const body = {
    EMAIL: email, 
  }
  const data = await Post({ url, body, token })
  return data
}

export const createtarget = async ({ email,fdate,tdate,targetamount, token }) => {
  const url = "/api/createtarget"
  const body = {
    EMAIL: email,
    FROMDATE:fdate,
    TODATE:tdate,
    TARGETAMOUNT:targetamount,
    
  }
  const data = await Post({ url, body, token })
  return data
}


export const addremark = async (request,token) => {
  const url = "/api/addremark"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const viewremark = async ({ mobile,token }) => {
  const url = "/api/viewremark"
  const body = {
   
    STUDENT_MOBILE: mobile,
   
  }
  const data = await Post({ url, body, token })
  return data
}



export const newdemorequest = async ({ email,course,searchtext, token }) => {
  const url = "/api/newdemorequest"
  const body = {
    USER_EMAIL: email,
    COURSE:course,
    SEARCH:searchtext
  }
  const data = await Post({ url, body, token })
  return data
}

export const addtomytrack = async ({ email, mobile, token }) => {
  const url = "/api/addtomytrack"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_MOBILE: mobile,
  }
  const data = await Post({ url, body, token })
  return data

}


export const mydemowatchlistapi = async (request,token) => {
  const url = "/api/mydemowatchlist"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const remainderdemowatchlistapi = async (request,token) => {
  const url = "/api/wathlistremainder"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const nobatchdatatapi = async (request,token) => {
  const url = "/api/nobatchdata"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const addpayment = async ({ semail,payid,amount,paydate, token }) => {
  const url = "/api/addpayment"
  const body = {
    STUDENT_EMAIL: semail,
    PAYMENT_ID:payid,
    AMOUNT:amount,
    PAYMENT_DATE:paydate,
  }
  const data = await Post({ url, body, token })
  return data
}


export const createbatch = async ({ batch,batchlead_email,status,completed_date,startdate,course,courseprice,discount,token }) => {
  const url = "/api/createbatch"
  const body = {
  BATCH: batch,
  BATCH_LEAD_EMAIL: batchlead_email,
  STATUS: status,
  COMPLETED_DATE: completed_date,
  START_DATE: startdate,
  COURSE: course,
  COURSE_PRICE: courseprice,
  COURSE_DISCOUNT: discount
  }
  const data = await Post({ url, body, token })
  return data
}

export const editbatch = async ({ id,batch,batchlead_email,status,completed_date,startdate,course,courseprice,discount,token }) => {
  const url = "/api/editbatch"
  const body = {
  BID:id,
  BATCH: batch,
  BATCH_LEAD_EMAIL: batchlead_email,
  STATUS: status,
  COMPLETED_DATE: completed_date,
  START_DATE: startdate,
  COURSE: course,
  COURSE_PRICE: courseprice,
  COURSE_DISCOUNT: discount
  }
  const data = await Post({ url, body, token })
  return data
}


export const addstudent = async (request,token) => {
  const url = "/api/addstudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const addDemostudent = async (request,token) => {
  const url = "/api/adddemostudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editstudent = async (request,token) => {
  const url = "/api/editstudentdata"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editDemostudent = async (request,token) => {
  const url = "/api/editdemostudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}



export const getbatchstudent = async (request,token) => {
  const url = "/api/getbatchstudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const loadrevenueapi = async (request,token) => {
  const url = "/api/revenuetrack"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getInvoicenumber = async (token) => {
  const url = "/api/generateinvoicenumber"
  const data = await Get(url, token)
  return data
}

export const viewinvoiceitems = async (request,token) => {
  const url = "/api/viewinvoice"
  const body =  request
  const data = await Post({ url, body, token })
  return data
}


export const deleteinvoiceapi = async (request,token) => {
  const url = "/api/deleteinvoice"
  const body = request
  const data = await Post({ url, body, token })
  return data
}



export const getdashboardheader = async (token) => {
  const url = "/api/dashboardheader"
  const data = await Get( url, token )
  return data
}

export const getdashboardbarchartinvoice = async (request,token) => {
  const url = "/api/dashboardinvoicechart"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getdemorequestdashboardbarchart = async (request,token) => {
  const url = "api/dashboardrevenuechart"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const getdashboardpiechart = async (request,token) => {
  const url = "/api/dashboardpaichart"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getteamreport = async (request,token) => {
  const url = "/api/marketingreport"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getuserdisplay = async (request,token) => {
  const url = "/api/userdisplay"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const adduser = async (request,token) => {
  const url = "/api/addtaguser"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const edituser = async (request,token) => {
  const url = "/api/edittaguser"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const deleteuser = async (request,token) => {
  const url = "/api/deletetaguser"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const displyStory = async (token) => {
  const url = "/api/displaystory"
  const data = await Get(url, token)
  return data
}


export const deleteDemostudentdata = async (request,token) => {
  const url = "/api/deletedemostudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const deleteRemarkdata = async (request,token) => {
  const url = "/api/deleteremark"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editRemarkdata = async (request,token) => {
  const url = "/api/editremarks"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const editstorydata = async (request,token) => {
  const url = "/api/editstory"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const displayprofile = async (request,token) => {
  const url = "/api/displayprofile"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const profileupdate = async (request,token) => {
  const url = "/api/updateprofile"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const couponupdate = async (request,token) => {
  const url = "/api/setcouponcode"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const changepassword = async (request,token) => {
  const url = "/api/changepassword"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const changemarketingowner = async (request,token) => {
  const url = "/api/changemarketingowner"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const loadusercombo = async (request,token) => {
  const url = "/api/usercombodata"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const demouserdata = async (request,token) => {
  const url = "/api/demouserdetails"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const addpaymentdata = async (request,token) => {
  const url = "/api/addpayment"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editpaymentdata = async (request,token) => {
  const url = "/api/paymentupdate"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const deletepaymentdata = async (request,token) => {
  const url = "/api/paymentdelete"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const displaypaymentdata = async (request,token) => {
  const url = "/api/paymentdisplay"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const addfeesdata = async (request,token) => {
  const url = "/api/feeadd"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const savecourse = async (request,token) => {
  const url = "/api/courseupdate"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const displaybatchdetails = async (request,token) => {
  const url = "/api/bathdetails"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const batchlist = async (request,token) => {
  const url = "/api/batchdropdown"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const dispstudentdetails = async (request,token) => {
  const url = "/api/studentdetails"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const dispearning = async (request,token) => {
  const url = "/api/earnings"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editadminuser = async (request,token) => {
  const url = "/api/useradminedit"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const earningdata = async (request,token) => {
  const url = "/api/earningdata"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const salesreportdata = async (request,token) => {
  const url = "/api/salesreport"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const revenuereportdata = async (request,token) => {
  const url = "/api/revenuereport"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const targetreportdata = async (request,token) => {
  const url = "/api/targetreport"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const curiostuffadddata = async (request,token) => {
  const url = "/api/curiostuffadd"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const curiostuffrevenue = async (request,token) => {
  const url = "/api/curiostuffrevenue"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const curiostuffdatadisplay = async (request,token) => {
  const url = "/api/curiostuffdisplay"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const curiostuffdatadelete = async (request,token) => {
  const url = "/api/curiostuffdelete"
  const body = request
  const data = await Post({ url, body, token })
  return data
}


export const coursedisplayapi = async (request,token) => {
  const url = "/api/coursedisplay"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const coralframesadddata = async (request,token) => {
  const url = "/api/coralframesadd"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const packageadddata = async (request,token) => {
  const url = "/api/packagesadd"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const packagedisplaydata = async (token) => {
  const url = "/api/packagedisplay"
  const data = await Get(url, token)
  return data
}

export const coralframesrevenue = async (request,token) => {
  const url = "/api/coralframesrevenue"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const coralframesdatadisplay = async (request,token) => {
  const url = "/api/coralframesdisplay"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const coralframesdatadelete = async (request,token) => {
  const url = "/api/coralframesdelete"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const packagedatadelete = async (request,token) => {
  const url = "/api/packagedelete"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

