import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom"
import { useSelector } from "react-redux"
import ScrollToTop from "src/hooks/scrollToTop"

import Login from "src/component/Login/Login"
import Main from "src/component/Main/Main"
import Dashboard from "src/component/Dashboard/Dashboard"
import Billing from "src/component/Billing/Billing"
import Investment from "src/component/Investment/Investment"
import Reports from "src/component/Reports/Reports"
import Packages from "src/component/Packages/Packages"

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="" element={<Login />} />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <Routes>
                  <Route path="/" element={<Main />}>
                    <Route path="/dashboard" index element={<Dashboard />} />
                    <Route path="/Billing" index element={<Billing />} />
                    <Route path="/Investment" element={<Investment />} />
                    <Route path="/Reports" element={<Reports />} />
                    <Route path="/Packages" element={<Packages />} />
                  </Route>
                </Routes>
              </RequireAuth>
            }
          />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

const RequireAuth = ({ children }) => {
  let user = useSelector((state) => state.user)
  let location = useLocation()

  if (!user?.token) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

export default App
