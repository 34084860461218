import React, { useState } from "react"
import styles from "./Login.module.css"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { login } from "src/api/api"
import { MdEmail } from "react-icons/md"
import { RiLockPasswordFill } from "react-icons/ri"
import ActivityIndicator from "src/component/ActivityIndicator/ActivityIndicator"
import { setUser } from "src/redux/reducer/userReducer"

const Field = React.forwardRef(
  ({ label, placeholder, icon, error, ...props }, ref) => {
    return (
      <>
        <div className={styles.input}>
          {icon}
          <input
            {...props}
            ref={ref}
            placeholder={placeholder}
            className={styles.inputStyle}
          />
        </div>
        <p className={styles.validerror}>{error}</p>
      </>
    )
  }
)
const Login = () => {
  const [passwordError, setPasswordError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const emailRef = React.useRef()
  const passwordRef = React.useRef()

  const handleSubmit = async () => {
    setEmailError(null)
    setPasswordError(null)

    const email = emailRef.current.value

    if (document.getElementById("email").value.length === 0) {
      setEmailError("Please enter your email id")
      return
    }

    const password = passwordRef.current.value

    if (document.getElementById("password").value.length === 0) {
      setPasswordError("Please enter your password")
      return
    }
    //TODO email and password validation

    setLoading(true)
    const data = await login({ email: email, password: password })

    const user = {
      token: data.TOKEN,
      email: data.USER_EMAIL,
      full_name: data.USER_FULLNAME,
      mobile: data.USER_MOBILE,
      status: data.USER_STATUS,
    }
    if (user.token) {
      dispatch(setUser(user))
      // if(data.USER_STATUS ==='REFERRAL USER'){
      //   navigate("/Demotracker")
      // }else{
        navigate("/Dashboard")
      // }
      
    } else {
      setPasswordError(data.Message)
      setLoading(false)
      return
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };


  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          style={{
            width: "150px",
            height: "40px",
            alignSelf: "center",
            //marginRight: "16px",
          }}
          alt="logo.png"
          src={"./images/cntlogonbg.png"}
        />
        
      </div>
      <div className={styles.innerDiv}>
        <h1 className={styles.heading}>Contotool, Billing Platform For Coralframes</h1>
        <div className={styles.linebox}>
          <div className={styles.leftline} />
          <div className={styles.rightline} />
        </div>
        <div className={styles.form}>
          <Field
            id="email"
            icon={<MdEmail size={20} />}
            type="text"
            onKeyPress={handleKeyPress}
            ref={emailRef}
            placeholder="Email"
            // Email="required"
            error={emailError}
          />
          <Field
            icon={<RiLockPasswordFill size={20} />}
            id="password"
            type="password"
            onKeyPress={handleKeyPress}
            ref={passwordRef}
            placeholder="Password"
            error={passwordError}
          />
          <Link to="/Forgotpassword" className={styles.link}>
            Forgot Password?
          </Link>
          <button
            disabled={loading}
            onClick={() => handleSubmit()}
            className={styles.loginBtn}
          >
            {loading ? (
              <ActivityIndicator />
            ) : (
              <p className={styles.linkItem}>Log In</p>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
export default Login
