import React, { useEffect, useRef,useState,useCallback } from "react"
import styles from "./Main.module.css"
import useWindowDimensions from "src/hooks/useWindowDimensions"
import { BiBell } from "react-icons/bi"
import { Link, NavLink, Outlet } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logoutUser } from "src/redux/reducer/userReducer"
import PeopleIcon from '@mui/icons-material/People';
import Button from "@mui/material/Button";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dashboard from "src/component/Dashboard/Dashboard"
import DashboardIcon from '@mui/icons-material/Dashboard';
import Billing from "src/component/Billing/Billing"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Investment from "src/component/Investment/Investment"
import { useSelector } from "react-redux";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Reports from "src/component/Reports/Reports"
import Packages from "src/component/Packages/Packages"




const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Main = () => {
  const container = window !== undefined ? () => window().document.body : undefined;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const sideNavRef = useRef(null)
  const mainRef = useRef(null)

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [modelopen, setModelopen] = React.useState(false);

  const [clickedmenu, setClickedmenu] = useState();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [refreshKey, setRefreshKey] = useState(0);

  const refreshComponent = () => {
    setRefreshKey(prevNumber => prevNumber + 1);
  };

  const handlermenuclick = (click) => {
    refreshComponent()
    switch(click) { 
      case 'DashBoard': 
      setClickedmenu(()=>{return(<Dashboard onRefresh={refreshKey} />)});
      return;
      case 'Billing': 
      setClickedmenu(()=>{return(<Billing  />)});
       return;
      case 'Quotation': 
      //setClickedmenu(()=>{return(<Demotracker onRefresh={refreshKey}  />)});
      return;
      case 'Investment': 
      setClickedmenu(()=>{return(<Investment onRefresh={refreshKey} />)});
      return;
      case 'Packages': 
      setClickedmenu(()=>{return(<Packages onRefresh={refreshKey} />)});
      return;
      case 'My Profile': 
      //setClickedmenu(()=>{return(<Myprofile/>)});
      return;
      case 'Logout': 
      dispatch(logoutUser());
      return;
      default: 
      return null;
      
    }
  };
  

  useEffect(() => {
    if(user?.status ==='REFERRAL USER'){
    //setClickedmenu(()=>{return(<Demotracker/>)});
  }else{
    setClickedmenu(()=>{return(<Dashboard/>)});
    }
  }, [user.token])


  const hasWindow = typeof window !== 'undefined';
  const [widthsize, setWidthsize] = useState();
  const [width, setWidth] = useState(hasWindow ? window.innerWidth : null);
  let timeOutId = useRef();

useEffect(() => {
  const handleResize = () => {
    setWidth(window.innerWidth);
  }
  window.addEventListener('resize', () => {
    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(handleResize, 500);
  });
}, []);

useEffect(() => {
  if (width !== null && width < 500) {
    setWidthsize(300)
  } else if (width !== null && width > 500) {
    setWidthsize(1000)
  } else {
    setWidthsize(100)
  }
}, [width])

  const handleNavBar = () => {
    if (width > 900) {
      sideNavRef.current.style.display = "flex"
      sideNavRef.current.style.flexDirection = "column"
      mainRef.current.style.marginLeft = "0px"
      sideNavRef.current.style.width = "0px"
      mainRef.current.style.paddingTop = "0"
    } else {
      sideNavRef.current.style.width = "100%"
      mainRef.current.style.marginLeft = "0px"
      mainRef.current.style.paddingTop = "52px"
      closeSideNav()
    }
  }

  const openSideNav = () => {
    sideNavRef.current.style.width = "100%"
  }

  const closeSideNav = () => {
    sideNavRef.current.style.width = "0%"
  }

  const _logout = ()=>{
   
  }

  return (
    
   <Box>
    <Box>
      <AppBar sx={{ backgroundColor: '#f0f0f0' }} position="fixed" open={open} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src="./images/cntlogonbg.png" className={styles.logo} />
      

          <Box sx={{ flexGrow: 1 }} />
          
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

          {(user?.email ==='sachinck4@gmail.com'||user?.email ==='deepak@tagskillsinfo.com'||user?.email ==='aishwarya@tagskillsinfo.com') && (
          <IconButton size="small" aria-label="Earnings" color="inherit"
            onClick={() => {
              handlermenuclick("CURIOSTUFF");
            }}
           // onClick={() => {setModelopen(true)}}
            >
             
                <CurrencyRupeeIcon  sx={{
                    height: 16,width:16
                  }}  variant="plain" color="neutral" />
                <Typography  level="title-sm">
                Curiostuff</Typography>
            </IconButton>
          )}

            {/* <IconButton size="small" aria-label="Earnings" color="inherit"
            onClick={() => {
              handlermenuclick("Earnings");
            }}
           // onClick={() => {setModelopen(true)}}
            >
             
                <CurrencyRupeeIcon  sx={{
                    height: 16,width:16
                  }}  variant="plain" color="neutral" />
                <Typography  level="title-sm">
                Earnings</Typography>
            </IconButton> */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={''}
              aria-haspopup="true"
              onClick={() => {
                handlermenuclick("My Profile");
              }}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
    
        </Toolbar>
      </AppBar>
      </Box>
    <Box sx={{ display: "flex" }}>
 
      <CssBaseline />
    
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div ref={sideNavRef}>
          <List>

            {user?.status !=='REFERRAL USER' && (
            <ListItem
              key={"DashBoard"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Dashboard"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("DashBoard");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DashboardIcon></DashboardIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"DashBoard"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>)}

            {user?.status !=='REFERRAL USER' && (
            <ListItem
              key={"Billing"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Billing"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Billing");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PeopleIcon></PeopleIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Billing"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>)}

            {/* <ListItem
              key={"Quotation"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Demotracker"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Quotation");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ContactPhoneIcon></ContactPhoneIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Quotation"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem> */}

            {user?.status !=='REFERRAL USER' && (
            <ListItem
              key={"Investment"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Investment"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Investment");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CastForEducationIcon></CastForEducationIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Investment"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>)}

            {user?.status !=='REFERRAL USER' && (
            <ListItem
              key={"Packages"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Packages"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Packages");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CastForEducationIcon></CastForEducationIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Packages"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>)}


            {user?.status !=='REFERRAL USER' && (
            <ListItem
              key={"Reports"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Reports"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Reports");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AssessmentIcon></AssessmentIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Reports"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>)}



{(user?.email ==='sachinck4@gmail.com' || user?.email ==='investment@tagskills.com') && (
            <ListItem
              key={"Investment"}
              disablePadding
              sx={{ display: "block" }}
            >
              <NavLink
                to={"/Investment"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Investment");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PermContactCalendarIcon></PermContactCalendarIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Investment"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
)}
            
          </List>

          <Divider />
          <List>
            <ListItem key={"Logout"} disablePadding sx={{ display: "block" }}>
              <NavLink
                to={"/Login"}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navItem
                }
              >
                <ListItemButton
                  onClick={() => {
                    handlermenuclick("Logout");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ExitToAppIcon></ExitToAppIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Logout"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Box  container={container} ref={mainRef} component="main"  sx={{ marginTop:'5%',width:{widthsize}}}>
        
          {/* <DrawerHeader /> */}
          {clickedmenu}
       
        </Box>
    </Box>
    </Box>
  );
}

export default Main
