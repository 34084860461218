import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/joy/Typography";
import { DataGrid } from "@mui/x-data-grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Invoice from "./Invoice";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Modal from "@mui/joy/Modal";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { getInvoicenumber, saveinvoice,viewinvoiceitems ,editinvoiceapi,getclientdetailsapi,packagedisplaydata} from "src/api/api";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux"
import { logoutUser } from "src/redux/reducer/userReducer"


const Billingdetails = ({setShowBillingDetails,selectedRows}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const [tabvalue, setTabvalue] = React.useState(
    user?.status === "REFERRAL USER" ? "2" : "1"
  );
  const [invoicedate, setInvoicedate] = useState(
    dayjs(moment().format("DD-MMM-YYYY h:mm A"))
  );

  const [invoicedescription, setInvoicedescription] = useState('');
  const [packagedata, setPackagedata] = useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [invoicequantity, setInvoicequantity] = useState("");
  const [invoiceamount, setInvoiceamount] = useState("");

  const [invoicemodelopen, setInvoicemodelopen] = useState(false);
  const [paymenttype, setPaymenttype] = useState("");
  const [transactionid, setTransactionid] = useState("");

  const [invoiceto, setInvoiceto] = useState("");
  const [invoicecontactnumber, setInvoicecontactnumber] = useState("");
  const [invoiceaddress, setInvoiceaddress] = useState("");
  const [invoiceemail, setInvoiceemail] = useState("");
  const [invoicegstnumber, setInvoicegstnumber] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [gstTotal, setGstTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({ name: "", age: "" });
  const [load, setLoad] = useState(false);
  const [txtinvoicenumber, setTxtinvoicenumber] = useState("");
  const [checked, setChecked] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [otheramounts,setOtheramounts] =useState([]);

  

  const columns = [
    { field: "SNo", headerName: "S.No", width: 70 },
    { field: "Description", headerName: "Description", width: 500 },
    { field: "Amount", headerName: "Amount", width: 150 },
    { field: "Quantity", headerName: "Quantity", width: 120 },
    { field: "TotalAmount", headerName: "Total Amount", width: 200 },
    {
      field: "action",
      headerName: "Remove",
      width: 100,
      renderCell: (params) => (
        <DeleteOutlineIcon
          variant="contained"
          color="secondary"
          onClick={() => removeRow(params.row.SNo)}
        >
          Remove
        </DeleteOutlineIcon>
      ),
    },
  ];

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(amount);
  };


  const loadpackages = async () => {
  
    const data = await packagedisplaydata(user?.token);
    if(data==401)
    {
      dispatch(logoutUser());
      return;
    }
    setPackagedata(data?.details)
 
  };

  useEffect(() => {
    getinvnumber();
    loadpackages();
  }, []);

  useEffect(() => {
    if(!invoicemodelopen && selectedRows ==='')
    {
      setInvoicedate(dayjs(moment().format("DD-MMM-YYYY h:mm A")));
      getinvnumber();
      setPaymenttype('')
      setRows([]);
      setTransactionid('');
      setInvoiceto('');
      setInvoiceemail('');
      setInvoicecontactnumber('');
      setInvoiceaddress('');
      setInvoicegstnumber('');
      setInvoicedescription('');
      setInvoiceamount('');
      setInvoicequantity('');
    }
    
  }, [invoicemodelopen]);

  

  useEffect(() => {
    const total = rows.reduce(
      (acc, row) => acc + parseFloat(row.TotalAmount),
      0
    );
    setSubTotal(total.toFixed(2));
    if (checked) {
      setGstTotal((total * 0.18).toFixed(2));
      setGrandTotal(
        parseFloat(total.toFixed(2)) + parseFloat((total * 0.18).toFixed(2))
      );
    } else {
      setGstTotal(0);
      setGrandTotal(parseFloat(total.toFixed(2)).toFixed(2));
    }

  }, [rows, checked]);

  const getinvnumber = async () => {

    if(Object.keys(selectedRows).length >0)
    {
      setTxtinvoicenumber(selectedRows?.INVOICENUMBER);
      setPaymenttype(selectedRows?.PAYMENT_TYPE)
      setTransactionid(selectedRows?.PAYMENT_ID);
      setInvoiceto(selectedRows?.CUSTOMER_NAME||'');
      setInvoiceemail(selectedRows?.CUSTOMER_EMAIL);
      setInvoicecontactnumber(selectedRows?.CUSTOMER_NUMBER);
      setInvoiceaddress(selectedRows?.CUSTOMER_ADDRESS);
      setInvoicegstnumber(selectedRows?.CUSTOMER_GST);
      setInvoicedate( dayjs(moment(selectedRows?.INVOICEDATE).format("DD-MMM-YYYY h:mm A")));
      
      const requestBody = {
        INVOICENUMBER: selectedRows?.INVOICENUMBER,
      };

      const data = await viewinvoiceitems(requestBody,user.token);
      if(data==401)
    {
      dispatch(logoutUser());
      return;
    }
      if(data?.IsSuccess)
      {
      const transformedItems = data?.INVOICE_ITEMS_LIST_DETAILS.map((item,index) => (
          {
            SNo: index+1 ,
            Description: item.PRODUCT_DESCRIPTION,
            Amount: item.PRODUCT_AMOUNT,
            Quantity: item.PRODUCT_QUANTITY,
            TotalAmount: item.PRODUCT_AMOUNT * item.PRODUCT_QUANTITY,
          }
      ));

      setRows(transformedItems);

      const gstdata = data?.INVOICE_ITEMS_LIST_DETAILS.map((item,index) => (
        setChecked(item.PRODUCT_GST==='18' ? true: false)
      ));

      }

      setLoad(false);

    }else{
    setLoad(true);
    const data = await getInvoicenumber(user.token);
    setTxtinvoicenumber(data?.INVOICENUMBER);
    setLoad(false);
    }
  };

  const addRow = () => {

    if(invoicedescription ==='' || invoicedescription==null)
    {
      return;
    }

    if(invoicequantity ==='' || invoicequantity==null)
    {
      return;
    }

    if(invoiceamount ==='' || invoiceamount==null)
    {
      return;
    }

    setRows([
      ...rows,
      {
        SNo: rows.length + 1,
        Description: invoicedescription,
        Amount: invoiceamount,
        Quantity: invoicequantity,
        TotalAmount: invoiceamount * invoicequantity,
      },
    ]);
  
  };

  const removeRow = (id) => {
    setRows(rows.filter((row) => row.SNo !== id));
  };

  const handleSubmit = async () => {
    
    if(txtinvoicenumber ==='' || txtinvoicenumber==null)
    {
      return;
    }

    if(invoicecontactnumber ==='' || invoicecontactnumber==null)
    {
      return;
    }

    if(rows.length ==0)
    {
      alert('Add the Product details for billing!')
      return;
    }

    const transformedItems = rows.map((item) => ({
      DESCRIPTION: item.Description,
      QUANTITY: item.Quantity,
      AMOUNT: item.Amount,
      GST: checked == true ? "18" : "0",
    }));

    const tempdate = dayjs(invoicedate).format("DD-MMM-YYYY h:mm A");
    const requestBody = {
      INVOICENUMBER: txtinvoicenumber,
      INVOICEDATE: tempdate,
      PAYMENT_TYPE: paymenttype,
      PAYMENT_ID:transactionid,
      CUSTOMER_NAME: invoiceto,
      CUSTOMER_EMAIL: invoiceemail,
      CUSTOMER_NUMBER: invoicecontactnumber,
      CUSTOMER_ADDRESS: invoiceaddress,
      CUSTOMER_GST: invoicegstnumber,
      ITEMS: transformedItems,
    };
   
    setLoad(true);
    if(Object.keys(selectedRows).length >0)
    {
      const data = await editinvoiceapi(requestBody, user.token);
      if (data?.IsSuccess) {
        setResponseData(requestBody);
        setOtheramounts({
          SUBTOTAL: subTotal,
          GSTTOTAL: gstTotal,
          GRANDTOTAL: grandTotal
        });
        setInvoicemodelopen(true);
      }
    }else
    {

      console.log('9999',requestBody)
      const data = await saveinvoice(requestBody, user.token);
    if (data?.IsSuccess) {
      setResponseData(requestBody);
      setOtheramounts({
        SUBTOTAL: subTotal,
        GSTTOTAL: gstTotal,
        GRANDTOTAL: grandTotal
      });
      setInvoicemodelopen(true);
    }
    }

    setLoad(false);

  };

  const searchsubmitHandler = async () => {

    const requestBody = {
      MOBILE: invoicecontactnumber,
    };

    const data = await getclientdetailsapi(requestBody, user.token);
    if (data?.IsSuccess) {
      setInvoiceto(data?.CUSTOMER_DEATAILS[0]?.CUSTOMER_NAME);
      setInvoiceemail(data?.CUSTOMER_DEATAILS[0]?.CUSTOMER_EMAIL);
      setInvoiceaddress(data?.CUSTOMER_DEATAILS[0]?.CUSTOMER_ADDRESS);
      setInvoicegstnumber(data?.CUSTOMER_DEATAILS[0]?.CUSTOMER_GST);
    }else
    {
      alert('Data Not Found');
    }

  }

  return (
    <div>
      {Object.keys(selectedRows).length > 0 && (
        <Button
          size="small"
          sx={{ textTransform: "none" }}
          variant="text"
          startIcon={<KeyboardBackspaceIcon />}
          onClick={() => {
            setShowBillingDetails(false);
          }}
        >
          Back
        </Button>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: "lg", minHeight: "100%", border: 0 }}>
            <Card sx={{ boxShadow: "lg", minHeight: "100%", border: 0 }}>
              <Typography level="body-sm">Payment details</Typography>
              <Stack direction="row" sx={{ marginTop: 1 }} spacing={4}>
                <FormControl sx={{ m: 0 }} size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                    }
                    label="GST(18%)"
                  />
                </FormControl>

                <FormControl sx={{ m: 0, minWidth: 340 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={paymenttype}
                    label="Payment Type"
                    onChange={(e) => {
                      setPaymenttype(e.target.value);
                    }}
                  >
                    <MenuItem value={"Debit Card"}>Debit Card</MenuItem>
                    <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
                    <MenuItem value={"Net Banking"}>Net Banking</MenuItem>
                    <MenuItem value={"Gpay"}>Gpay</MenuItem>
                    <MenuItem value={"PhonePe"}>PhonePe</MenuItem>
                    <MenuItem value={"Paytm"}>Patym</MenuItem>
                    <MenuItem value={"PayPal"}>PayPal</MenuItem>
                    <MenuItem value={"Cred"}>Cred</MenuItem>
                    <MenuItem value={"Cash"}>Cash</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={transactionid?.length == 0}
                  value={transactionid}
                  id="outlined-basic"
                  label="transaction Id"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTransactionid(e.target.value);
                  }}
                  helperText={
                    transactionid.length == 0 ? "Enter paymentid!" : ""
                  }
                />
              </FormControl>
            </Card>
            <Card sx={{ boxShadow: "lg", minHeight: "100%", border: 0 }}>
              <Typography level="body-sm">Product details</Typography>

              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Autocomplete
                 size="small"
                  freeSolo
                  onChange={(event, value) => {
                    setInvoicedescription(value?.NAME);
                    setInvoicequantity("1");
                    setInvoiceamount(value?.AMOUNT);
                  }}
                  id="free-solo-2-demo"
                  options={packagedata}
                  getOptionLabel={(option) => option.NAME}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Package"
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          type: "search",
                        },
                      }}
                    />
                  )}
                />
               
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <TextField
                  size="small"
                  required
                  error={invoicequantity?.length == 0}
                  value={invoicequantity}
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setInvoicequantity(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <TextField
                  size="small"
                  required
                  error={invoiceamount?.length == 0}
                  value={invoiceamount}
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setInvoiceamount(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    addRow();
                  }}
                >
                  Add
                </Button>
              </FormControl>
            </Card>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: "lg", minHeight: "100%", border: 0 }}>
            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  defaultValue={dayjs(invoicedate).format("DD-MMM-YYYY h:mm A")}
                  value={dayjs(invoicedate)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD-MMM-YYYY h:mm A"
                  error={invoicedate?.length == 0}
                  label="Date Time"
                  onChange={(newValue) =>
                    setInvoicedate(dayjs(newValue).format("DD-MMM-YYYY h:mm A"))
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ m: 0, minWidth: 250 }}>
              <TextField
                size="small"
                required
                error={txtinvoicenumber?.length == 0}
                disabled
                value={txtinvoicenumber}
                id="outlined-basic"
                label="Invoice Number"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  txtinvoicenumber.length == 0 ? "Refresh the page" : ""
                }
              />
            </FormControl>
            <Typography level="body-sm">Customer details</Typography>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <TextField
                size="small"
                required
                error={invoiceto?.length == 0}
                value={invoiceto}
                id="outlined-basic"
                label="To"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setInvoiceto(e.target.value);
                }}
                helperText={
                  invoiceto?.length == 0 ? "Enter customer name!" : ""
                }
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputBase
                  size="small"
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Customer Details"
                  inputProps={{ "aria-label": "search customer details" }}
                  error={invoicecontactnumber?.length == 0}
                  value={invoicecontactnumber}
                  onChange={(e) => {
                    setInvoicecontactnumber(e.target.value);
                  }}
                  helperText={
                    invoicecontactnumber?.length == 0
                      ? "Enter customer contact number!"
                      : ""
                  }
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => searchsubmitHandler()}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              {/* <TextField
                size="small"
                required
                error={invoicecontactnumber?.length == 0}
                value={invoicecontactnumber}
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setInvoicecontactnumber(e.target.value);
                }}
                helperText={
                  invoicecontactnumber?.length == 0
                    ? "Enter customer contact number!"
                    : ""
                }
              /> */}
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <TextField
                size="small"
                required
                error={invoiceemail?.length == 0}
                value={invoiceemail}
                id="outlined-basic"
                label="Emailid"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setInvoiceemail(e.target.value);
                }}
                helperText={
                  invoiceemail?.length == 0 ? "Enter customer email!" : ""
                }
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <TextField
                required
                multiline
                maxRows={10}
                rows={2}
                error={invoiceaddress?.length == 0}
                value={invoiceaddress}
                id="outlined-basic"
                label="Address"
                variant="outlined"
                onChange={(e) => {
                  setInvoiceaddress(e.target.value);
                }}
                helperText={
                  invoiceaddress?.length == 0 ? "Enter customer address!" : ""
                }
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <TextField
                size="small"
                required
                error={invoicegstnumber?.length == 0}
                value={invoicegstnumber}
                id="outlined-basic"
                label="GST Number"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setInvoicegstnumber(e.target.value);
                }}
                helperText={
                  invoicegstnumber?.length == 0 ? "Enter customer GST!" : ""
                }
              />
            </FormControl>
          </Card>
        </Grid>
      </Grid>

      <div style={{ width: "100%", marginTop: 10 }}>
        <DataGrid
          getRowId={(row) => row.SNo}
          rows={rows}
          columns={columns}
          pageSize={5}
        />
        <Card
          sx={{
            minHeight: "20%",
            marginTop: 2,
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  minHeight: "20%",
                  marginTop: 2,
                  justifyContent: "flex-end",
                  textAlign: "right",
                }}
              >
                <Typography level="title-sm">Sub Total:</Typography>
                <Typography level="title-sm">Total IGST(18%): </Typography>
                <Typography level="title-sm">Grand Total:</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  marginTop: 2,
                  justifyContent: "flex-end",
                  textAlign: "right",
                }}
              >
                <Typography level="title-sm">
                  {formatCurrency(subTotal)}
                </Typography>
                <Typography level="title-sm">
                  {formatCurrency(gstTotal)}
                </Typography>
                <Typography level="title-sm">
                  {formatCurrency(grandTotal)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Align child elements to the end
            m: 1,
            minWidth: 400,
          }}
        >
          <FormControl>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                handleSubmit();
              }}
              sx={{ width: "400px" }}
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={invoicemodelopen}
        onClose={() => {
          setInvoicemodelopen(false);
        }}
        fullWidth
        maxWidth="maxWidth"
        sx={{
          marginTop: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
          height: "90%",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            marginTop: 20,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" />

          <Invoice
            responseData={responseData}
            otheramounts={otheramounts}
          ></Invoice>
        </Sheet>
      </Modal>
    </div>
  );
};

export default Billingdetails;
