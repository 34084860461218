import { Avatar, CardHeader, Divider, Grid, TextField } from "@mui/material";
import {
    coralframesadddata,
    coralframesrevenue,
    coralframesdatadisplay,
    coralframesdatadelete
} from "src/api/api";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./Investment.module.css";
import Button from '@mui/material/Button';
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import SvgIcon from "@mui/joy/SvgIcon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridFooter,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from "@mui/x-data-grid";
  import RefreshIcon from '@mui/icons-material/Refresh';
  import ModalClose from "@mui/joy/ModalClose";
  import Sheet from "@mui/joy/Sheet";
  import Modal from "@mui/joy/Modal";
import { logoutUser } from "src/redux/reducer/userReducer"



export default function Investment() {

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <Button href="#text-buttons"
                size="small"
                onClick={() => {
                    loaddatagrid();
                }}
              >
                {" "}
                <RefreshIcon />
                Refresh
              </Button>
      
          </GridToolbarContainer>
        );
      }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [from,setFrom] = useState(moment().format('01-01-YYYY')||'');
  const [todate,setTodate] = useState(moment().format('DD-MMM-YYYY')||''||'');
  const [load, setLoad] = useState(false);
  const [addmodelopen, setAddmodelopen] = useState(false);


  const [dispdata,setDispdata] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);


  const [name, setName] = useState('');
  const [invtype, setInvtype] = useState('');
  const [amount, setAmount] = useState('');
  const [remark, setRemark] = useState('');
  const [datetime, setDatetime] = useState(moment().format('DD-MMM-YYYY')||''||'');


  const [totalinvestment, setTotalinvestment] = useState(0);
  const [totaloexpenses, setTotaloexpenses] = useState(0);
  const [totalwithdrawal, setTotalwithdrawal] = useState(0);
  const [curiorevenue, setCuriorevenue] = useState(0);

  const [deletemodelopen, setDeletemodelopen] = useState(false);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (!navigator.geolocation) {
//       setError('Geolocation is not supported by your browser');
//     } else {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLocation({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude
//           });
//         },
//         (err) => {
//           setError(err.message);
//         }
//       );
//     }
//   }, []);

// console.log('9999',location)

  const columns = [
    {
      field: "SN",
      headerName: 'SNO',
      width: 75,
      type: "number",
      editable: false,
    },
    {
        field: "NAME",
        headerName: 'Name',
        width: 150,
        editable: false,
      },
    {
      field: "AMOUNT",
      headerName: 'Amount',
      width: 100,
      editable: false,
    },
    {
      field: "INV_TYPE",
      headerName: 'Activity Type',
      width: 150,
      editable: false,
    },
    {
      field: "DATETIME",
      headerName: 'Date Time',
      type: "datetime",
      sort: "desc",
      width: 180,
      sortComparator: (a, b) => moment(a).diff(moment(b)),
      editable: false,
    },
    {
        field: "REMARK",
        headerName: 'Remark',
        type: "number",
        width: 400,
        editable: false,
      },
    {
      field: "Delete",
      headerName: 'Delete',
      width: 65,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <DeleteForeverIcon
            aria-label="delete"
            size="large"
            onClick={() => {
              setSelectedData(cellValues.row);
              setDeletemodelopen(true);
            }}
          >
          </DeleteForeverIcon>
        );
      },
    },
  ];



  useEffect(() => {
    loaddatagrid();
    displayearning();
  }, []);



  const deletsubmitHandler = async(tempselectedData)=>{

    const request ={
        ID:tempselectedData?.ID
    }
    const res = await coralframesdatadelete(request,user.token)
    if (res.IsSuccess) {
        loaddatagrid();
        displayearning();
        setDeletemodelopen(true);
    } else {
      
     alert(res.Message)
    }
    setDeletemodelopen(false);

  }

  const convertToDecimal = (inputValue) => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      return value.toFixed(2);
    } else {
      return 0;
    }
  };


  const loaddatagrid = async () => {

    const request = {
       FROMDATE : dayjs(from).format("DD-MMM-YYYY"),
       TODATE: dayjs(todate).format("DD-MMM-YYYY"),
    }
    const data = await coralframesdatadisplay(request, user?.token);
    if(data==401)
    {
      dispatch(logoutUser());
      return;
    }
    const res = data?.details.map((row, index) => ({
        ...row,
        SN: index + 1,
      }));
    setResponseData(res);

  };

  const displayearning = async () => {

    const request = {
       FROMDATE : dayjs(from).format("DD-MMM-YYYY"),
       TODATE: dayjs(todate).format("DD-MMM-YYYY"),
    }
    const data = await coralframesrevenue(request, user?.token);
    setDispdata(data?.details);

    const tlinv = data?.details?.reduce((accumulator, currentObject) => {
        return parseInt(accumulator) + parseInt(currentObject.INVESTMENT);
      }, 0)
    setTotalinvestment(tlinv);

      setTotaloexpenses(data?.details?.reduce((accumulator, currentObject) => {
        return parseInt(accumulator) + parseInt(currentObject.EXPENSES);
      }, 0));

      const sal =(data?.details?.reduce((accumulator, currentObject) => {
        return parseInt(accumulator) + parseInt(currentObject.SALARYWITHDRAWAL);
      }, 0));

      const profit =(data?.details?.reduce((accumulator, currentObject) => {
        return parseInt(accumulator) + parseInt(currentObject.PROFITWITHDRAWAL);
      }, 0));

      setTotalwithdrawal(sal+profit)

      setCuriorevenue(profit - tlinv)


  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(amount);
  };


  const submitHandler = async () => {

    if(amount=== '')
    {
        alert('Enter Amount');
        return;
    }

    if(name=== '')
    {
        alert('Select name');
        return;
    }

    if(invtype=== '')
    {
        alert('Select investment type');
        return;
    }

    if(remark=== '')
    {
        alert('Enter Remark');
        return;
    }

    let res;
  
      const request = {
        NAME: name,
        INV_TYPE: invtype,
        AMOUNT: amount,
        REMARK: remark,
        DATETIME: datetime,
      };
      res = await coralframesadddata(request, user.token);
    
    //loadUserData();
    alert(res.Message);
    setName('')
    setInvtype('')
    setAmount('')
    setRemark('')
    setDatetime(moment().format('DD-MMM-YYYY'))
    loaddatagrid();
    displayearning();
    setAddmodelopen(false);
  };

  return (
    <Grid sx={{ width: "100%" }} container spacing={1}>
      
      <Grid sx={{ marginLeft:4,marginTop:4 , minWidth: 100 }} md={12}>
     
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From Date"
                format="DD-MMM-YYYY"
                defaultValue={dayjs(from)}
                slotProps={{ textField: { size: "small" } }}
                onChange={(newValue) =>
                  setFrom(dayjs(newValue).format("DD-MMM-YYYY"))
                }
              />
            </LocalizationProvider>{" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs(todate)}
                slotProps={{ textField: { size: "small" } }}
                label="To Date"
                format="DD-MMM-YYYY"
                error={todate.length == 0}
                onChange={(newValue) =>
                  setTodate(dayjs(newValue).format("DD-MMM-YYYY"))
                }
              />
            </LocalizationProvider>{" "}
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                displayearning();
              }}
            >
              Show Data
            </Button>


            <Button  sx={{ marginLeft:10 }}
              variant="contained"
              size="medium"
              onClick={() => {
                setAddmodelopen(true);
              }}
            >
              Add Payment Activity
            </Button>
            
         
            <Divider  sx={{ m: 1, minWidth: 100 }} variant="middle" />
          </Grid>

          {/*  ..........other Part .......... */}

    


        <CardActions disableSpacing sx={{ marginLeft:4,marginTop:0 }}>
          
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            
            <ExpandMoreIcon />
         
          </ExpandMore>
          <Typography level="title-sm">View All Data </Typography>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          
        <Box sx={{ marginLeft:4,marginTop:4 ,height: "90%" }}>
          <DataGrid
            getRowId={(row) => row.ID}
            rows={responseData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
          
            loading={load}
            pageSizeOptions={[50, 100]}
            onRowSelectionModelChange={(newSelection) => {
              const selectedIDs = new Set(newSelection);
              const selectedRows = responseData.filter((row) =>
                selectedIDs.has(row.ID)
              );
              setSelectedRows(selectedRows);
            }}
          />
        </Box>
      

      </Collapse>


   {/* ...........CURIOSTUFFF........... */}
      <Grid md={12}>
            <Card
              sx={{ m: 4, minWidth: 100,marginTop:2,marginBottom:2 }}
              variant="solid"
              color="primary"
              invertedColors
            >
              <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path style={{color:parseInt(curiorevenue) < 0 ?  " #8B0000" :"#32CD32"}}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  </SvgIcon>
                </CircularProgress>
                <CardContent>
                  <Typography level="body-md">Coralframes Revenue is in {parseInt(curiorevenue) < 0 ?'Loss':'Profit'}</Typography>
                  <Typography level="h2" sx={{ color: parseInt(curiorevenue) < 0 ?  " #8B0000" :"#32CD32" }} >
                    {formatCurrency(curiorevenue || "00")}
                  </Typography>
                </CardContent>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>
          
          <Grid md={4}>
            <Card
              sx={{m: 4, minWidth: 100,marginTop:1 ,marginBottom:1}}
              variant="solid"
              color="primary"
              invertedColors
            >
              <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  </SvgIcon>
                </CircularProgress>
                <CardContent>
                  <Typography level="body-md">Total Investment</Typography>
                  <Typography level="h2">
                    {formatCurrency(totalinvestment || "00")}
                  </Typography>
                </CardContent>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>

          <Grid md={4}>
            <Card
              sx={{ m: 4, minWidth: 100,marginTop:1 ,marginBottom:1 }}
              variant="solid"
              color="primary"
              invertedColors
            >
              <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  </SvgIcon>
                </CircularProgress>
                <CardContent>
                  <Typography level="body-md">Total Expenses</Typography>
                  <Typography level="h2">
                    {formatCurrency(totaloexpenses || "00")}
                  </Typography>
                </CardContent>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>

          <Grid md={4}>
        <Card
          sx={{ m: 4, minWidth: 100 ,marginTop:1,marginBottom:1 }}
          variant="solid"
          color="primary"
          invertedColors
        >
          <CardContent orientation="horizontal">
            <CircularProgress size="lg" determinate value={20}>
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                  />
                </svg>
              </SvgIcon>
            </CircularProgress>
            <CardContent>
              <Typography level="body-md">Total Withdrawals</Typography>
              <Typography  level="h2">
                {formatCurrency(totalwithdrawal)}
              </Typography>
            </CardContent>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </Grid>


{/* ................... ...........*/}

{dispdata?.map((item) =>(
     <>
     <Grid md={4}>
            <Card
              sx={{ m: 4, minWidth: 100,marginTop:1,marginBottom:0 }}
              variant="solid"
              color="primary"
              invertedColors
            >
              <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  </SvgIcon>
                </CircularProgress>
                <CardContent>
                  <Typography level="body-md">{item?.NAME} Investment</Typography>
                  <Typography level="h2">
                    {formatCurrency(item?.INVESTMENT || "00")}
                  </Typography>
                </CardContent>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>

          <Grid md={4}>
        <Card
          sx={{ m: 4, minWidth: 100,marginTop:1,marginBottom:0  }}
          variant="solid"
          color="primary"
          invertedColors
        >
          <CardContent orientation="horizontal">
            <CircularProgress size="lg" determinate value={20}>
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                  />
                </svg>
              </SvgIcon>
            </CircularProgress>
            <CardContent>
              <Typography level="body-md">Total Withdrawals</Typography>
              <Typography  level="h2">
              {formatCurrency(parseInt(item?.SALARYWITHDRAWAL) + parseInt(item?.PROFITWITHDRAWAL) || "00")}
              </Typography>
            </CardContent>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </Grid>

          <Grid md={4}>
            <Card
              sx={{ m: 4, minWidth: 100,marginTop:1,marginBottom:0 }}
              variant="solid"
              color="primary"
              invertedColors
            >
              <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path style={{color: ((parseInt(item?.SALARYWITHDRAWAL) + parseInt(item?.PROFITWITHDRAWAL)) - parseInt(item?.INVESTMENT)) < 0 ?"#FF4500" :"#00FF00"}}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  </SvgIcon>
                </CircularProgress>
                <CardContent>
                  <Typography level="body-md">Profit/Loss</Typography>
                  <Typography level="h2" 
                  sx={{ color: ((parseInt(item?.SALARYWITHDRAWAL) + parseInt(item?.PROFITWITHDRAWAL)) - parseInt(item?.INVESTMENT)) < 0 ?  " #8B0000" :"#32CD32" }} >
                    {formatCurrency(   (parseInt(item?.SALARYWITHDRAWAL) + parseInt(item?.PROFITWITHDRAWAL)) - parseInt(item?.INVESTMENT) || "00")}
                  </Typography>
                </CardContent>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>

         
      </>
))}



      <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={deletemodelopen}
          onClose={() => setDeletemodelopen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 1400,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h2"
              variant="h6"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Confirm Deletion!
            </Typography>
            <Typography>
              {/* Mobile : {selectedRows.USER_MOBILE} Email :{" "}
            {selectedRows.USER_EMAIL} */}
            </Typography>
            <div className="modal-content">
            <Typography sx={{ p: 1,marginBottom: 6  }}>Are you sure you want to delete {selectedData?.NAME} Amount :{selectedData?.AMOUNT} data? data will be permanently removed. This action cannot be undone.</Typography>
            <Divider />
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Button
            variant="contained"
            size="medium"
            onClick={() => setDeletemodelopen(false)}>
            Cancel
          </Button>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Button
          color="error"
            variant="contained"
            size="medium"
            onClick={() => deletsubmitHandler(selectedData)}>
            Delete
          </Button>
        </FormControl>
      </div>
          </Sheet>
        </Modal>



{/* ............ADD PAYMENT ACTIVITY............ */}

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={addmodelopen}
          onClose={() => {
            setAddmodelopen(false);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              minWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Add Payment Activity
            </Typography>

            <Grid item xs={12} md={6}>
         
         <Card sx={{ boxShadow: "lg",minHeight:'100%', minWidth:500, border:0 }}>

         <FormControl sx={{ m: 1 }}>
         <TextField
           size="small"
           value={amount}
           required
           error={amount.length == 0}
           id="outlined-basic"
           label="Amount"
           type="number"
           variant="outlined"
           onChange={(e) => {
             setAmount(e.target.value);
           }}
         />
 
       </FormControl>
         <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
             <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker
                 label="Date"
                 slotProps={{ textField: { size: "small" } }}
                 value={dayjs(datetime)}
                 format="DD-MMM-YYYY"
                 // error={remark?.length == 0}
                 onChange={(newValue) =>
                     setDatetime(dayjs(newValue).format("DD-MMM-YYYY"))
                 }
               />
             </LocalizationProvider>
           </FormControl>
 
           <FormControl sx={{ m: 1,  }} size="small">
             <InputLabel id="demo-simple-select-label">Name</InputLabel>
             <Select
              // error={remark?.length == 0}
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={name}
               label="Status"
               onChange={(e) => {
                 setName(e.target.value);
               }}
             >
               <MenuItem value={"Royal"}>Royal</MenuItem>
               <MenuItem value={"Harijith"}>Harijith</MenuItem>
               <MenuItem value={"Sachin"}>Sachin</MenuItem>
             </Select>
           </FormControl>

           <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
             <InputLabel id="demo-simple-select-label">Activity Type</InputLabel>
             <Select
              // error={remark?.length == 0}
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={invtype}
               label="Status"
               onChange={(e) => {
                 setInvtype(e.target.value);
               }}
             >
               <MenuItem value={"Investment"}>Investment</MenuItem>
               <MenuItem value={"Loan Paid"}>Loan Paid</MenuItem>
               <MenuItem value={"Withdrawal"}>Withdrawal</MenuItem>
             </Select>
           </FormControl>

           <FormControl sx={{ m: 1, minWidth: 100 }}>
             <TextField
               required
               multiline
               maxRows={10}
               rows={2}
               error={remark?.length == 0}
               value={remark}
               id="outlined-basic"
               label="Enter Remark"
               variant="outlined"
               onChange={(e) => {
                 setRemark(e.target.value);
               }}
             />
           </FormControl>
          
           <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
             <Button
               variant="contained"
               fullWidth
               onClick={(e) => {
                 submitHandler();
               }}
             >
               Add
             </Button>
           </FormControl>

           </Card>
 </Grid>
 

          </Sheet>
        </Modal>

    </Grid>
  );
}
