import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { displayinvoicehistory, deleteinvoiceapi } from "src/api/api";
import Billingdetails from "./Billingdetails";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Divider from "@mui/material/Divider";

const Billinghistory = () => {
  const user = useSelector((state) => state.user);
  const [load, setLoad] = useState(false);
  const [rows, setRows] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deletemodelopen, setDeletemodelopen] = useState(false);
  const [snackbarstatus, setSnackbarstatus] = useState("");
  const [snackbarstatusmsg, setSnackbarstatusmsg] = useState("");
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [open, setOpen] = React.useState(false);

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (cellValues) => (
        <EditIcon
          variant="contained"
          color="secondary"
          onClick={() => {
            setSelectedRows(cellValues.row);
            setShowBillingDetails(true);
          }}
        >
          Edit
        </EditIcon>
      ),
    },
    { field: "sl", headerName: "S.No", width: 70 },
    { field: "INVOICENUMBER", headerName: "Invoice Number", width: 200 },
    { field: "INVOICEDATE", headerName: "Date", width: 200 },
    { field: "CUSTOMER_NAME", headerName: "Name", width: 200 },
    { field: "CUSTOMER_EMAIL", headerName: "Email", width: 200 },
    { field: "CUSTOMER_NUMBER", headerName: "Mobile", width: 200 },
    { field: "CUSTOMER_ADDRESS", headerName: "Address", width: 200 },
    { field: "CUSTOMER_GST", headerName: "GST", width: 200 },
    { field: "PAYMENT_TYPE", headerName: "Payment Type", width: 200 },
    { field: "PAYMENT_ID", headerName: "Payment Id", width: 200 },
    {
      field: "action",
      headerName: "Remove",
      width: 100,
      renderCell: (cellValues) => (
        <DeleteOutlineIcon
          variant="contained"
          color="secondary"
          onClick={() => {
            setSelectedRows(cellValues.row);
            setDeletemodelopen(true);
          }}
        >
          Remove
        </DeleteOutlineIcon>
      ),
    },
  ];

  useEffect(() => {
    invoicehistoryapifunc();
  }, []);

  const invoicehistoryapifunc = async () => {
    setLoad(true);
    const requestBody = {
      FROMDATE: "",
      TODATE: "",
    };
    const data = await displayinvoicehistory(requestBody, user.token);
    if (data?.IsSuccess) {
      setResponseData(data?.INVOICE_LIST_DETAILS);
    }
    setLoad(false);
  };


  const deletsubmitHandler = async () => {
    const request = {
      INVOICENUMBER: selectedRows?.INVOICENUMBER,
    };
    const res = await deleteinvoiceapi(request, user.token);
    if (res.IsSuccess) {

      setRows(rows.filter((row) => row.ID !== selectedRows?.ID));
      invoicehistoryapifunc();
      setSnackbarstatus("success");
      setSnackbarstatusmsg(res.Message);
      setOpen(true);

    } else {

      setSnackbarstatus("error");
      setSnackbarstatusmsg(res.Message);
      setOpen(true);

    }
    setDeletemodelopen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={deletemodelopen}
        onClose={() => setDeletemodelopen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 1400,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h2"
            variant="h6"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Confirm Deletion!
          </Typography>
          <Typography>
            {/* Mobile : {selectedRows.USER_MOBILE} Email :{" "}
            {selectedRows.USER_EMAIL} */}
          </Typography>
          <div className="modal-content">
            <Typography sx={{ p: 1, marginBottom: 6 }}>
              Are you sure you want to delete Invoice :{" "}
              {selectedRows?.INVOICENUMBER}'s data? data will be permanently
              removed. This action cannot be undone.
            </Typography>
            <Divider />
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Button
                variant="contained"
                size="medium"
                onClick={() => setDeletemodelopen(false)}
              >
                Cancel
              </Button>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Button
                color="error"
                variant="contained"
                size="medium"
                onClick={() => deletsubmitHandler()}
              >
                Delete
              </Button>
            </FormControl>
          </div>
        </Sheet>
      </Modal>

      {showBillingDetails == false && (
        <DataGrid
          getRowId={(row) => row.ID}
          columns={columns}
          rows={responseData}
          pageSize={5}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
          loading={load}
          pageSizeOptions={[50, 100]}
          disableColumnResize={false}
          columnBuffer={2}
        />
      )}
      {showBillingDetails && (
        <Billingdetails
          setShowBillingDetails={setShowBillingDetails}
          selectedRows={selectedRows}
        />
      )}
    </div>
  );
};

export default Billinghistory;
