import React, { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import Typography from "@mui/joy/Typography";
import { Margin } from "@mui/icons-material";
import { toWords } from 'number-to-words';
import "jspdf-autotable";


const Invoice = ({responseData,otheramounts}) => {

 
  const other =otheramounts;
  const data =responseData;
  const invoiceRef = useRef();

  function numberToWords(num) {
    const singleDigits = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const twoDigits = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const tenToNineteen = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const scales = ["", "Thousand", "Lakh", "Crore"];

    if (num === 0) return "Zero Rupees Only";

    let words = "";
    let crorePart = Math.floor(num / 10000000);
    let lakhPart = Math.floor((num % 10000000) / 100000);
    let thousandPart = Math.floor((num % 100000) / 1000);
    let hundredPart = Math.floor((num % 1000) / 100);
    let tensAndOnesPart = num % 100;

    if (crorePart > 0) {
        words += `${convertBelowThousand(crorePart)} Crore `;
    }
    if (lakhPart > 0) {
        words += `${convertBelowThousand(lakhPart)} Lakh `;
    }
    if (thousandPart > 0) {
        words += `${convertBelowThousand(thousandPart)} Thousand `;
    }
    if (hundredPart > 0) {
        words += `${singleDigits[hundredPart]} Hundred `;
    }
    if (tensAndOnesPart > 0) {
        words += convertBelowHundred(tensAndOnesPart) + " ";
    }

    return words.trim() + " Rupees Only";
}

function convertBelowHundred(num) {
    const singleDigits = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const twoDigits = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const tenToNineteen = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

    if (num < 10) return singleDigits[num];
    if (num >= 10 && num < 20) return tenToNineteen[num - 10];
    return twoDigits[Math.floor(num / 10)] + " " + singleDigits[num % 10];
}

function convertBelowThousand(num) {
    if (num < 100) return convertBelowHundred(num);
    const singleDigits = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    return singleDigits[Math.floor(num / 100)] + " Hundred " + convertBelowHundred(num % 100);
}

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};



const handleDownloadPDF = async () => {
  const pdf = new jsPDF("p", "pt", "a4");

  const pageWidth = pdf.internal.pageSize.getWidth();

  // Load company logo from the public folder (ensure it's accessible)
  const logoPath = `${window.location.origin}/images/corallogo.png`;

  // Convert image to Base64
  const base64Logo = await getBase64ImageFromURL(logoPath);

  // Header function to add company logo, address, and client details
  const addHeader = () => {
    if (base64Logo) {
      pdf.addImage(base64Logo, "PNG", 20, 20, 80, 50); // Adjust position and size as needed
    }
                
    pdf.setFontSize(10);
    pdf.text("7892276371, contact@coralframes.com, www.coralframes.com", 20, 80);
    //pdf.text("contact@coralframes.com", 120, 60);
    //pdf.text("www.coralframes.com, ", 20, 95);

    pdf.setFontSize(16);
    const textX = pageWidth / 2; // Center the text horizontally
    const textY = 30;
    pdf.setFontSize(16);
    pdf.text("Invoice", textX, textY, { align: "center" });

    // Add an underline by drawing a line below the text
    const textWidth = pdf.getTextWidth("Invoice");
    pdf.line(textX - textWidth / 2, textY + 2, textX + textWidth / 2, textY + 2); 

    pdf.setFontSize(12);
    pdf.text("Coralframes Pvt Ltd", pageWidth - 20, 30, { align: "right" });
    pdf.setFontSize(10);
    pdf.text("Sierra Cartel,#1207 /343,", pageWidth - 20, 45, { align: "right" });
    pdf.text("9th Main, 7th Sector, HSR Layout,", pageWidth - 20, 57, { align: "right" });
    pdf.text("Bangalore,Karnataka,IND-560068", pageWidth - 20, 69, { align: "right" });
    pdf.text("GST :                               ", pageWidth - 20, 81, { align: "right" });
    
    pdf.setDrawColor(180, 180, 180);
    pdf.line(20, 90, pageWidth - 20, 90); 
  };

  const addClient = () => {
  pdf.setFontSize(10);
  pdf.text("Bill To:", 20, 120);
  pdf.setFontSize(10);
  pdf.text(data?.CUSTOMER_NAME, 20, 135);
  pdf.text('Contact Number: '+data?.CUSTOMER_NUMBER, 20, 150);
  pdf.text('Email: '+data?.CUSTOMER_EMAIL, 20, 165);
  pdf.text('Address: '+data?.CUSTOMER_ADDRESS, 20, 180);
  pdf.text('GST: '+data?.CUSTOMER_GST, 20, 195);

  pdf.setFontSize(10);
  pdf.text('Date : '+ data?.INVOICEDATE, pageWidth - 20, 120, { align: "right" });
  pdf.text('Invoice No. : '+ data?.INVOICENUMBER, pageWidth - 20, 135, { align: "right" });
  pdf.text('Payment Type : '+ data?.PAYMENT_TYPE, pageWidth - 20, 150, { align: "right" });
  pdf.text('Payment ID : '+ data?.PAYMENT_ID, pageWidth - 20, 165, { align: "right" });

  }




  //pdf.setFont("Roboto");
  const columns = [
    { header: "SNo.", dataKey: "sno" },
    { header: "Description", dataKey: "description" },
    { header: "Amount(INR)", dataKey: "amount" },
    { header: "Quantity", dataKey: "quantity" },
    { header: "Total Amount(INR)", dataKey: "totalamount" }
  ];



  const rows = data?.ITEMS.map((item, index) => ({
    sno: index + 1,
    description: item.DESCRIPTION,
    amount: item.AMOUNT,
    quantity: item.QUANTITY,
    totalamount: item.QUANTITY * item.AMOUNT
  }));

  // Add header to the first page
  addHeader();
  addClient();

  // Draw table with autoTable, handling page overflow
  pdf.autoTable({
    columns,
    body: rows,
    startY: 210, // Start after the header
    theme: "grid",
    margin: { top: 210, left: 20, right: 20 },
    styles: { fontSize: 10, cellPadding: 4 },
    headStyles: { fillColor: [255, 255, 255] ,textColor: [0,0,0],lineWidth: 1, // Width of the border
    lineColor: [128,128,128],halign: 'center'},
    bodyStyles: { valign: "middle", halign: "center" },
    didDrawPage: (data) => {
      if (data.pageNumber > 1) 
      {
      addHeader();
      addClient();
      }
    }
  });


  const finalY = pdf.lastAutoTable.finalY; // Get the Y position after the table
  pdf.setFontSize(10);
  pdf.text(`Sub Total(INR)      : ${formatCurrency(other?.SUBTOTAL)}`, 400, finalY + 20);
  pdf.text(`GST Total(INR)     : ${formatCurrency(other?.GSTTOTAL)}`, 400, finalY + 40);
  pdf.text(`Grand Total (INR) : ${formatCurrency(other?.GRANDTOTAL)}`, 400, finalY + 60);
  pdf.setDrawColor(180, 180, 180);
  pdf.line(20, finalY+80, pageWidth - 20, finalY+80); 
  pdf.text(numberToWords(other?.GRANDTOTAL), 20, finalY + 100);
  pdf.text('', 400, finalY + 140);
  pdf.text(`E & O.E`, 20, finalY + 160);
  pdf.text(`* This is computer generated invoice and does not need signature.`, 20, finalY + 180);
  pdf.text(`* Please use the invoice number as the payment reference.`, 20, finalY + 200);
  pdf.text(`* All applicable taxes (GST etc.) are included/excluded (depending on the invoice) in this invoice. Please refer to the breakdown `, 20, finalY + 220);
  pdf.text(`for GST rates and amounts. The buyer is responsible for compliance with applicable tax regulations.`, 20, finalY + 240);
  pdf.text(`* For any queries related to this invoice, please contact contact@coralframes.com`, 20, finalY + 260);
  // Save the PDF

  pdf.save("invoice.pdf");
};

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.crossOrigin = "Anonymous"; // This may not be needed for local images
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
  });
};




  // const handleDownloadPDF = async () => {
  //   const element = invoiceRef.current;

  //   // Use html2canvas to capture the invoice element
  //   const canvas = await html2canvas(element, { scale: 2 });
  //   const imgData = canvas.toDataURL("image/png");

  //   // Create a new PDF with A4 dimensions
  //   const pdf = new jsPDF('p', 'mm', 'a4');

  //   // Set margin values (in mm for jsPDF)
  //   const marginX = 15; // Left and right margins
  //   const marginY = 15; // Top and bottom margins
  
  //   // Get canvas width and height to calculate the size of the image in the PDF
  //   const canvasWidth = canvas.width;
  //   const canvasHeight = canvas.height;
  
  //   // Set the width and height for the image in the PDF (minus margins)
  //   const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * marginX; // Subtract margins from PDF width
  //   const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;       // Maintain aspect ratio
  
    
  //   const imgWidth = canvas.width;
  //   const imgHeight = canvas.height;
  //   const ratio = imgWidth / pdfWidth;

  //   // Calculate scaled height for the A4 page width
  //   const pageHeight = pdfHeight * ratio;
  //   let position = 0;

  //   // Loop to add pages if content overflows
 
  //   // Add image to PDF with margin
  //   while (position < imgHeight) {
  //   pdf.addImage(
  //     imgData,                    // Image data
  //     'PNG',                       // Image format
  //     marginX,                     // X coordinate (left margin)
  //     marginY,                     // Y coordinate (top margin)
  //     pdfWidth,                    // Image width
  //     pdfHeight                    // Image height
  //   );

  //   position += pdfHeight;
  //   if (position < imgHeight) {
  //     pdf.addPage();
  //   }

  //   }

  //   // Save the PDF
  //   pdf.save('invoice.pdf');
  // };

  const handlePrintPDF = async () => {
    const element = invoiceRef.current;

    // Use html2canvas to capture the invoice element
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");

    // Create a PDF document using jsPDF
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 0, 0);

    // Open the PDF in a new tab for printing
    pdf.output("dataurlnewwindow");
  };

  return (
    <div>
      <div
        ref={invoiceRef}
        style={{
          padding: "1px",
          width: "700px",
          margin:2,
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderBottom: "1px solid #D3D3D3",
          }}
        >
          <tr>
            <td
              style={{
                verticalAlign: "top",
                width: "40%",
              }}
            >
              <img src="./images/corallogo.png" width="150" height="75" />
              <Typography level="body-sm">
                contact@coralframes.com, 7892276371,
              </Typography>{" "}
              <Typography level="body-sm">
                www.coralframes.com, gst: 123Abcd222
              </Typography>{" "}
            </td>

            <td
              style={{
                verticalAlign: "top",
                width: "20%",
              }}
            >
              <h3 style={{ textAlign: "center", textDecoration: "underline" }}>
                Invoice
              </h3>
            </td>

            <td style={{ textAlign: "right", width: "40%" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <Typography level="title-sm">
                        Coralframes Pvt Ltd
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Typography level="body-sm">
                        Sierra Cartel,#1207 /343 ,
                      </Typography>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Typography level="body-sm">
                        9th Main, 7th Sector, HSR Layout,
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Typography level="body-sm">
                        Bangalore,Karnataka,IND-560068
                      </Typography>
                    </td>
                    <td> </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>

        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                style={{
                  verticalAlign: "top",
                  padding: "10px",
                  textAlign: "left",
                  width: "50%",
                }}
              >
                <Typography sx={{ marginLeft: 2 }} level="title-sm">
                  To :
                </Typography>
                <Typography sx={{ marginLeft: 2 }} level="title-sm">
                  {data?.CUSTOMER_NAME}
                </Typography>
                <Typography sx={{ marginLeft: 2 }} level="body-sm">
                  Contact Number: {data?.CUSTOMER_NUMBER}
                </Typography>
                <Typography sx={{ marginLeft: 2 }} level="body-sm">
                  Email: {data?.CUSTOMER_EMAIL}
                </Typography>
                <Typography sx={{ marginLeft: 2 }} level="body-sm">
                  Address:  {data?.CUSTOMER_ADDRESS}
                </Typography>
                <Typography sx={{ marginLeft: 2 }} level="body-sm">
                  GST: {data?.CUSTOMER_GST}
                </Typography>
              </td>

              <td
                style={{
                  padding: "10px",
                  verticalAlign: "top",
                  textAlign: "right",
                  width: "50%",
                }}
              >
                <Typography level="body-sm">Date : {data?.INVOICEDATE}</Typography>
                <Typography level="title-sm">Invoice Number: {data?.INVOICENUMBER}</Typography>
                <Typography level="body-sm">Payment Type: { data?.PAYMENT_TYPE}</Typography>
                <Typography level="body-sm"> Payment ID: { data?.PAYMENT_ID }</Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            width: "97%",
            borderCollapse: "collapse",
            marginTop:'5px',
            marginLeft:'10px',
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid #D3D3D3", padding: "8px" }}>
                <Typography level="title-sm">SNo. </Typography>
              </th>
              <th style={{ border: "1px solid #D3D3D3", padding: "8px" }}>
                <Typography level="title-sm">Description</Typography>
              </th>
              <th style={{ border: "1px solid #D3D3D3", padding: "8px" }}>
                <Typography level="title-sm">Amount(₹)</Typography>
              </th>
              <th style={{ border: "1px solid #D3D3D3", padding: "8px" }}>
                <Typography level="title-sm">Quantity</Typography>
              </th>
              <th style={{ border: "1px solid #D3D3D3", padding: "8px" }}>
                <Typography level="title-sm">Total Amount(₹)</Typography>
              </th>
            </tr>
          </thead>
          <tbody>

          {data?.ITEMS.map((item,i) => 
            <tr>
              <td
                style={{
                  border: "1px solid #D3D3D3",
                  padding: "8px",
                  width: "5%",
                }}
              >
                <Typography level="body-sm">
                  {item[i]}
                </Typography>
              </td>
              <td
                style={{
                  border: "1px solid #D3D3D3",
                  padding: "8px",
                  width: "40%",
                }}
              >
                <Typography level="body-sm">
                {item.DESCRIPTION}
                </Typography>
              </td>
              <td
                style={{
                  border: "1px solid #D3D3D3",
                  padding: "8px",
                  textAlign: "right",
                  width: "20%",
                }}
              >
                <Typography  level="body-sm">
                {formatCurrency(item.AMOUNT)}
                </Typography>
              </td>
              <td
                style={{
                  border: "1px solid #D3D3D3",
                  padding: "8px",
                  textAlign: "center",
                  width: "10%",
                }}
              >
                <Typography  level="body-sm">
                {item.QUANTITY}
                </Typography>
              </td>
              <td
                style={{
                  border: "1px solid #D3D3D3",
                  padding: "8px",
                  textAlign: "right",
                  width: "25%",
                }}
              >
                <Typography  level="body-sm">
                {formatCurrency(item.QUANTITY * item.AMOUNT)}
                </Typography>
              </td>
            </tr>
            )}
          </tbody>
        </table>


        <table
          style={{
            width: "99%",
            borderCollapse: "collapse",
            marginTop:10,
            marginRight:0,
            borderBottom: "1px solid #D3D3D3",
          }}
        >
          <tr>
            <td style={{ padding: "5px", textAlign: "right" }}>
              <Typography level="title-sm">Sub Total: ₹{formatCurrency(other?.SUBTOTAL)}</Typography>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "right" }}>
              <Typography level="title-sm">GST Total(18%): ₹{formatCurrency(other?.GSTTOTAL)}</Typography>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "right" }}>
              <Typography level="title-md">Grand Total: ₹{formatCurrency(other?.GRANDTOTAL)}</Typography>
            </td>
          </tr>
        </table>
        <Typography level="body-sm">{numberToWords(other?.GRANDTOTAL)}</Typography>
        <br/>
        <Typography level="body-sm">E & O.E</Typography>
        <Typography level="body-sm">
        * This is computer generated invoice and does not need signature.</Typography>
        <Typography level="body-sm">* Please use the invoice number as the payment reference.</Typography>
        <Typography level="body-sm">* All applicable taxes (GST etc.) are included/excluded (depending on the invoice) in this invoice. Please refer to the breakdown for GST rates and amounts. The buyer is responsible for compliance with applicable tax regulations.</Typography>
        <Typography level="body-sm">* For any queries related to this invoice, please contact contact@coralframes.com</Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDownloadPDF()}
        style={{ display: "block", margin: "20px auto" }}
      >
        Save Invoice as PDF
      </Button>
    </div>
  );
};

export default Invoice;
