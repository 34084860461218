import React, { useEffect, useState } from "react";
import { CardActionArea } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Avatar from "@mui/material/Avatar";

const StatesticsCard = (props) => {
  return (
    <Card sx={{ maxWidth:  props?.mywidth }}>
      <CardActionArea>
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography gutterBottom variant="h6" component="div">
              <Avatar sx={{ bgcolor: "white", width: 60, height: 60 }}>
                <Badge badgeContent={props?.date} color="secondary">
                  <CalendarMonthIcon
                    sx={{ color: "black" }}
                  ></CalendarMonthIcon>
                </Badge>
              </Avatar>
            </Typography>
            <Typography variant="body1">{props?.heading}</Typography>
            <PeopleOutlineIcon />
          </Stack>
        </Box>
        <Divider variant="middle" />
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" component="div">
            {props?.count}{" "}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default StatesticsCard;
