import { Avatar, CardHeader, Divider, Grid, TextField } from "@mui/material";
import {
  packageadddata,
    coralframesrevenue,
    packagedisplaydata,
    packagedatadelete
} from "src/api/api";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./Packages.module.css";
import Button from '@mui/material/Button';
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import SvgIcon from "@mui/joy/SvgIcon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridFooter,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from "@mui/x-data-grid";
  import RefreshIcon from '@mui/icons-material/Refresh';
  import ModalClose from "@mui/joy/ModalClose";
  import Sheet from "@mui/joy/Sheet";
  import Modal from "@mui/joy/Modal";



export default function Packages() {

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <Button href="#text-buttons"
                size="small"
                onClick={() => {
                    loaddatagrid();
                }}
              >
                {" "}
                <RefreshIcon />
                Refresh
              </Button>
      
          </GridToolbarContainer>
        );
      }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [from,setFrom] = useState(moment().format('01-01-YYYY')||'');
  const [todate,setTodate] = useState(moment().format('DD-MMM-YYYY')||''||'');
  const [load, setLoad] = useState(false);
  const [addmodelopen, setAddmodelopen] = useState(false);


  const [dispdata,setDispdata] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);


  const [name, setName] = useState('');
  const [invtype, setInvtype] = useState('');
  const [amount, setAmount] = useState('');
  const [remark, setRemark] = useState('');
  const [datetime, setDatetime] = useState(moment().format('DD-MMM-YYYY')||''||'');


  const [totalinvestment, setTotalinvestment] = useState(0);
  const [totaloexpenses, setTotaloexpenses] = useState(0);
  const [totalwithdrawal, setTotalwithdrawal] = useState(0);
  const [curiorevenue, setCuriorevenue] = useState(0);

  const [deletemodelopen, setDeletemodelopen] = useState(false);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (!navigator.geolocation) {
//       setError('Geolocation is not supported by your browser');
//     } else {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLocation({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude
//           });
//         },
//         (err) => {
//           setError(err.message);
//         }
//       );
//     }
//   }, []);

// console.log('9999',location)

  const columns = [
    {
      field: "SN",
      headerName: 'SNO',
      width: 75,
      type: "number",
      editable: false,
    },
    {
        field: "NAME",
        headerName: 'Name Of Package',
        width: 300,
        editable: false,
      },
    {
      field: "AMOUNT",
      headerName: 'Amount',
      width: 100,
      editable: false,
    },
    {
      field: "Delete",
      headerName: 'Delete',
      width: 65,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <DeleteForeverIcon
            aria-label="delete"
            size="large"
            onClick={() => {
              setSelectedData(cellValues.row);
              setDeletemodelopen(true);
            }}
          >
          </DeleteForeverIcon>
        );
      },
    },
  ];



  useEffect(() => {
    loaddatagrid();
  }, []);



  const deletsubmitHandler = async(tempselectedData)=>{

    const request ={
        ID:tempselectedData?.ID
    }
    const res = await packagedatadelete(request,user.token)
    if (res.IsSuccess) {
        loaddatagrid();
        setDeletemodelopen(true);
    } else {
      
     alert(res.Message)
    }
    setDeletemodelopen(false);

  }

  const convertToDecimal = (inputValue) => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      return value.toFixed(2);
    } else {
      return 0;
    }
  };


  const loaddatagrid = async () => {

    const data = await packagedisplaydata(user?.token);
    const res = data?.details.map((row, index) => ({
        ...row,
        SN: index + 1,
      }));
    setResponseData(res);

  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(amount);
  };


  const submitHandler = async () => {

    if(remark=== '')
    {
        alert('Enter Package Name');
        return;
    }

    if(amount=== '')
    {
        alert('Enter Amount');
        return;
    }
  

    let res;
  
      const request = {
        NAME: remark,
        AMOUNT: amount
      };
      res = await packageadddata(request, user.token);
    
    //loadUserData();
    alert(res.Message);
    setAmount('')
    setRemark('')
    loaddatagrid();
    setAddmodelopen(false);
  };

  return (
    <Grid sx={{ width: "100%" }} container spacing={1}>
      
      <Grid sx={{ marginLeft:4,marginTop:4 , minWidth: 100 }} md={12}>
     
            <Button  sx={{ marginLeft:10 }}
              variant="contained"
              size="medium"
              onClick={() => {
                setAddmodelopen(true);
              }}
            >
              Add Packages
            </Button>
            
         
            <Divider  sx={{ m: 1, minWidth: 100 }} variant="middle" />
          </Grid>

          {/*  ..........other Part .......... */}

    


          <Box sx={{ marginLeft:4,marginTop:4 ,height: "90%", }}>
          <DataGrid
            getRowId={(row) => row.ID}
            rows={responseData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
          
            loading={load}
            pageSizeOptions={[50, 100]}
            onRowSelectionModelChange={(newSelection) => {
              const selectedIDs = new Set(newSelection);
              const selectedRows = responseData.filter((row) =>
                selectedIDs.has(row.ID)
              );
              setSelectedRows(selectedRows);
            }}
          />
        </Box>
    


      <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={deletemodelopen}
          onClose={() => setDeletemodelopen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 1400,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h2"
              variant="h6"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Confirm Deletion!
            </Typography>
            <Typography>
              {/* Mobile : {selectedRows.USER_MOBILE} Email :{" "}
            {selectedRows.USER_EMAIL} */}
            </Typography>
            <div className="modal-content">
            <Typography sx={{ p: 1,marginBottom: 6  }}>Are you sure you want to delete {selectedData?.NAME} Amount :{selectedData?.AMOUNT} data? data will be permanently removed. This action cannot be undone.</Typography>
            <Divider />
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Button
            variant="contained"
            size="medium"
            onClick={() => setDeletemodelopen(false)}>
            Cancel
          </Button>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Button
          color="error"
            variant="contained"
            size="medium"
            onClick={() => deletsubmitHandler(selectedData)}>
            Delete
          </Button>
        </FormControl>
      </div>
          </Sheet>
        </Modal>



{/* ............ADD PAYMENT ACTIVITY............ */}

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={addmodelopen}
          onClose={() => {
            setAddmodelopen(false);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              minWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Add Packages
            </Typography>

            <Grid item xs={12} md={6}>
         
         <Card sx={{ boxShadow: "lg",minHeight:'100%', minWidth:500, border:0 }}>
          
         <FormControl sx={{ m: 1, minWidth: 100 }}>
             <TextField
              size="small"
               required
               rows={2}
               error={remark?.length == 0}
               value={remark}
               id="outlined-basic"
               label="Enter Package Name"
               variant="outlined"
               onChange={(e) => {
                 setRemark(e.target.value);
               }}
             />
           </FormControl>
         <FormControl sx={{ m: 1 }}>
         <TextField
           size="small"
           value={amount}
           required
           error={amount.length == 0}
           id="outlined-basic"
           label="Amount"
           type="number"
           variant="outlined"
           onChange={(e) => {
             setAmount(e.target.value);
           }}
         />
 
       </FormControl>
    
          
           <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
             <Button
               variant="contained"
               fullWidth
               onClick={(e) => {
                 submitHandler();
               }}
             >
               Add
             </Button>
           </FormControl>

           </Card>
 </Grid>
 

          </Sheet>
        </Modal>

    </Grid>
  );
}
