import { createSlice } from '@reduxjs/toolkit'

const initialState = JSON.parse(localStorage.getItem('user')) 

const _updateUser = async (user)=>{
  localStorage.setItem('user', JSON.stringify(user))
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      _updateUser(state)
      state = action.payload
      return state
    },
    logoutUser: (state, action) => {
      _updateUser(null)
      return null
    },
  },
})

export const { setUser, logoutUser } = userSlice.actions

export default userSlice.reducer