import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Billingdetails from './Billingdetails';
import Billinghistory from './Billinghistory';


const Billing = ( ) => {

    const user = useSelector((state) => state.user);
    const [tabvalue, setTabvalue] = React.useState(user?.status ==='REFERRAL USER'? '2':'1');
    const [newopen, setNewopen] = useState(false);

    
    const handleChange = (event, newValue) => {
        setTabvalue(newValue);
      };

    return <div>

<Card sx={{ boxShadow: "lg", minHeight: "100%", minWidth: '80vw' }}>
            <TabContext value={tabvalue}>
              
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabvalue}
                  onChange={handleChange}
                  aria-label="icon position tabs example"
                >
                  <Tab
                    iconPosition="start"
                     icon={<ShoppingCartIcon />}
                    label="Create Invoice"
                    value="1"
                    disabled ={user?.status !=='REFERRAL USER'? false:true }
                  />
                  <Tab
                    icon={<ReceiptLongIcon />}
                    iconPosition="start"
                    label="Invoice History"
                    value="2"
                    disabled ={user?.status !=='REFERRAL USER'? false:true }
                  />
              
                </Tabs>
              </Box>

              <TabPanel
                sx={{
                  alignItems: "flex-start",
                }}
                value="1"
              >
                <Billingdetails setShowBillingDetails={''} selectedRows={''}  />
              </TabPanel>
              <TabPanel value="2">
                <Billinghistory/>
                {/* <OwnerDetails
                  selecteddata={selecteddata}
                  updateSelecteddata={updateSelecteddata}
                /> */}
              </TabPanel>
   

            </TabContext>
          </Card>
    </div>;
  };
  
  export default Billing;